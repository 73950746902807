.outer-container {
  position: relative;
  height: 1200px;
  overflow: hidden;
  margin-bottom: 4rem;
}

.inner-container {
  height: auto;
  overflow: hidden;
}

.app__header-container {
  position: relative;
  margin: auto;
  width: 100%;
  line-height: 0;
  -webkit-column-count: 7;
  -webkit-column-gap: 5px;
  -moz-column-count: 7;
  -moz-column-gap: 5px;
  column-count: 7;
  column-gap: 5px;
}

.outer-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(70%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  pointer-events: none;
  z-index: 1;
}

.app__header-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 5px;
}

@media screen and (min-width: 2000px) {
  .outer-container {
    height: 1000px;
  }
  .app__header-container {
    -webkit-column-count: 8;
    -moz-column-count: 8;
    column-count: 8;
  }
}

@media screen and (max-width: 1999px) {
  .outer-container {
    height: 900px;
  }
  .app__header-container {
    -webkit-column-count: 8;
    -moz-column-count: 8;
    column-count: 8;
  }
}

@media screen and (max-width: 1500px) {
  .outer-container {
    height: 1000px;
  }
  .app__header-container {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}

@media screen and (max-width: 1150px) {
  .outer-container {
    height: 1000px;
  }
  .app__header-container {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
}

@media screen and (max-width: 650px) {
  .outer-container {
    height: 900px;
  }
  .app__header-container {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}
