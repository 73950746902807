.app__brand {
  margin-top: -100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.app__brand div {
  flex: 1;
  max-width: 150px;
  min-width: 120px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand_images {
  width: 60px;
}

#village_hotels {
  width: 120px;
}
