.landing {
  font-family: var(--font-base);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.973);
  width: 460px;
  height: 580px;
  position: absolute;
  top: 20%;
  left: 20%;
}

.landing-intro {
  color: white;
  position: absolute;
  margin: auto;
  font-weight: 800;
  font-size: 32px;
  line-height: 55px;
  top: 80px;
  margin-right: 50px;
  margin-left: 30px;
}

.landing-intro p {
  margin: 0px;
}

#underlined {
  position: relative;
  display: inline;
}

#underline {
  position: absolute;
  width: 260px;
  left: -20px;
  bottom: 25px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 600px) {
  .landing {
    width: 300px;
    height: 400px;
    position: absolute;
    top: 20%;
    left: 10%;
  }

  .landing-intro {
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    top: 60px;
  }

  #underline {
    position: absolute;
    width: 180px;
    left: -5px;
    bottom: -17px;
  }
}
